<template>
  <div>
    <TopNav/>

    <div class="header">
      <div class="w w1200">
        <div class="logo">
          <div class="logoImg">
            <router-link :to="{path:'/'}">
              <img :src="require('@/assets/co/' + co + '/logo.png')">
            </router-link>
          </div>
        </div>
        <div class="dsc-search">

          <div class="form">
            <span v-if="showSearchMore">
              <span class="search-label-more">品名</span>
              <input autocomplete="off" v-model="more.name" type="text" class="search-input-more" placeholder="请输入品名" @keyup.enter="search">
              <span class="search-label-more">产地</span>
              <input autocomplete="off" v-model="more.factory" type="text" class="search-input-more" placeholder="请输入产地" @keyup.enter="search">
              <span class="search-label-more">规格</span>
              <input autocomplete="off" v-model="more.specs" type="text" class="search-input-more" placeholder="请输入规格" @keyup.enter="search">
            </span>
            <span v-if="!showSearchMore">
              <input autocomplete="off" v-model="keywords" type="text" class="search-text" placeholder="请输入关键字：品名 产地 规格（以空格间隔）" @keyup.enter="search">
            </span>
            <button class="button button-goods" v-on:click="search">搜商品</button>
            <button class="button button-goods" style="background-color: #b31919" v-on:click="showSearchMore = !showSearchMore">{{showSearchMore?'智能搜索':'条件搜索'}}</button>
            <ul class="keyword">
<!--              <li v-for="(search,index) in searches" v-bind:key="index">-->
<!--                <router-link :to="{path:'/search',query:{keywords:search}}" tag="a">-->
<!--                  {{search}}-->
<!--                </router-link>-->
<!--              </li>-->
              <li v-for="(item,index) in hostSearchList" v-bind:key="index">
                <a :href="item.websitePC" target="_blank">{{item.searchTerm}}</a>
              </li>
<!--              <li>-->
<!--                <router-link :to="{path:'/goods',query:{id:'1050861'}}" tag="a">多维元素片(21) 100片</router-link>-->
<!--              </li>-->
<!--              <li>-->
<!--                <router-link :to="{path:'/goods',query:{id:'1051519'}}" tag="a">雷贝拉唑钠肠溶片</router-link>-->
<!--              </li>-->
            </ul>

            <div class="suggestions_box" id="suggestions" style="display:none;">
              <div class="suggestions_list" id="auto_suggestions_list">
                </div>
            </div>

          </div>
        </div>
<!--        <div class="shopCart" v-on:mouseenter="mouseOver($event)" @mouseleave="mouseLeave($event)">-->
        <div class="shopCart">
          <div class="shopCart-con dsc-cm">
            <router-link :to="{path:'/cart'}" target="_blank">
              <i class="iconfont icon-carts"></i>
              <span>我的购物车</span>
              <em class="count cart_num">{{cartNum}}</em>
            </router-link>
          </div>
<!--          <div class="dorpdown-layer" v-if="cartList.length<=0">-->
<!--            <div class="prompt">-->
<!--              <div class="nogoods">-->
<!--                <b></b>-->
<!--                <span>购物车中还没有商品，赶紧选购吧！</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="dorpdown-layer" v-if="cartList.length>0">-->
<!--            <div class="settleup-content">-->
<!--              <div class="mc">-->
<!--                <ul>-->
<!--                  <li v-for="(cart,index) in cartList" v-bind:key="index">-->
<!--                    <div class="p-img">-->
<!--                      <router-link :to="{path:'goods',query:{id:cart.products.goodsId}}" target="_blank" tag="a">-->
<!--                        <img :src="cart.products.images" width="50" height="50">-->
<!--                      </router-link>-->
<!--                    </div>-->
<!--                    <div class="p-name">-->
<!--                      <router-link :to="{path:'goods',query:{id:cart.products.goodsId}}" target="_blank" tag="a">-->
<!--                        {{cart.products.name}}-->
<!--                      </router-link>-->
<!--                    </div>-->
<!--                    <div class="p-number">-->
<!--                      <amount-box v-model="cart.nums" @update="numChange(cart)" :defaultVal="cart.nums" :max="99999" :min="1"></amount-box>-->
<!--                    </div>-->
<!--                    <div class="p-oper">-->
<!--                      <div class="price"><em>¥</em>{{cart.products.price}}</div>-->
<!--                      <a href="javascript:void(0);" v-on:click="delCart(cart)" class="remove">删除</a>-->
<!--                    </div>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--              <div class="mb">-->
<!--                <div class="p-total">共{{cartNum}}件商品&nbsp;&nbsp;共计：{{carPrice}}</div>-->
<!--                <router-link :to="{path:'/cart'}" target="_blank" class="btn-cart">去购物车</router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>

    <div class="nav" ref="cateNav">
      <div class="w w1200">
        <div class="categorys" ref="cateDropDown">
          <div class="categorys-type">
            <a href="javascript:void(0)">全部商品分类</a>
          </div>
          <div class="categorys-tab-content">
            <div class="categorys-items">
              <div v-for="(item) in categories.filter(c => c.parentId == '0')" v-bind:key="item.id" class="categorys-item" v-on:mouseenter="mouseOver($event,'selected')" @mouseleave="mouseLeave($event,'selected')">
                <div class="item item-content">
                  <i class="iconfont icon-ele"></i>
                  <div class="categorys-title">
                    <strong>
                      <router-link :to="{path:'category',query:{id:item.id}}">
                        {{item.name}}
                      </router-link>
                    </strong>
                    <span>
                      <router-link :to="{path:'category',query:{id:second.id}}" v-for="(second) in categories.filter(c => c.parentId == item.id).slice(0,3)" v-bind:key="second.id">
                        {{second.name}}
                      </router-link>
                    </span>
                  </div>
                </div>
                <div class="categorys-items-layer" style="display: none;">
                  <div class="cate-layer-con clearfix">
                    <div class="cate-layer-left">
                      <div class="cate_channel">
                        <router-link :to="{path:'category',query:{id:second.id}}" v-for="(second) in categories.filter(c => c.parentId == item.id)" v-bind:key="second.id">
                          {{second.name}}
                        </router-link>
                      </div>
                    <div class="cate_detail">
                      <div class="item-brands">
                        <ul>
                        </ul>
                      </div>
                      <div class="item-promotions">
                      </div>
                    </div>
                  </div>
                    <div class="cate-layer-rihgt">
                      <div class="cate-brand">
<!--                        <div class="img" v-for="index of 6" :key="index">-->
<!--                          <a href="javascript:void(0);"><img src="@/assets/images/brand/brand_999.png"></a>-->
<!--                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clear"></div>
              </div>

            </div>
          </div>
        </div>
        <div class="nav-main">
          <ul class="navitems">
            <li>
              <router-link :to="{path:'/'}">首页</router-link>
            </li>

            <li>
              <router-link :to="{path:'/marketing/lottery/nineGrid'}">抽奖</router-link>
            </li>
            <!--
            <li>
              <router-link :to="{path:'/marketing/promotion',query:{id:'519012643986590341'}}">特价处理</router-link>
            </li>
            -->
            <!--
            <li>
              <router-link :to="{path:'/news'}">新闻资讯</router-link>
            </li>
            -->
<!--            <li>-->
<!--              <router-link to="/new">-->
<!--                新品主推-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <router-link to="/sanjiu">-->
<!--                三九专区-->
<!--              </router-link>-->
<!--            </li>-->
            <!--
            <li>
              <router-link to="/nvshen">
                康洲滋补专场
              </router-link>
            </li>
            -->
            <!--            <li>-->
            <!--              <router-link to="/sanbajie">-->
            <!--                约惠三八节专场-->
            <!--              </router-link>-->
            <!--            </li>-->
<!--            <li>-->
<!--              <router-link to="/huxi">-->
<!--                呼吸专场-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <router-link to="/liushen">-->
<!--                六神专场-->
<!--              </router-link>-->
<!--            </li>-->
            <!--
            <li>
              <router-link to="/jxy">
                江小药专场
              </router-link>
            </li>
            <li>
              <router-link to="/mxld">
                曼秀雷敦专场
              </router-link>
            </li>
            <li>
              <router-link to="/xiaolin">
                小林专场
              </router-link>
            </li>
            -->
<!--            <li>-->
<!--              <router-link to="/zb?t=20220825">-->
<!--                直播专区-->
<!--              </router-link>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
    </div>
    <div class="mui-mbar-tabs">
      <div class="quick_links_wrap" style="width: 40px;">
        <div class="quick_link_mian" data-userid="0">
        <div class="quick_links_panel">
          <div id="quick_links" class="quick_links">
            <ul>
              <li>
                <router-link :to="{path:'/member'}"><i class="setting"></i></router-link>
                <div class="ibar_login_box status_login" style="display: none;">
                  <div class="avatar_box">
                    <p class="avatar_imgbox">
                      <img src="@/assets/images/touxiang.jpg" width="100" height="100">
                    </p>
                    <ul class="user_info">
                      <li>用户名：暂无</li>
                      <li>级&nbsp;别：暂无</li>
                    </ul>
                  </div>
                  <div class="login_btnbox">
                    <a href="user.php?act=order_list" class="login_order">我的订单</a>
                    <a href="user.php?act=collection_list" class="login_favorite">我的收藏</a>
                  </div>
                  <i class="icon_arrow_white"></i>
                </div>
              </li>

              <li id="shopCart">
                <router-link :to="{path:'cart'}" target="_blank" tag="a" class="cart_list">
                  <i class="message"></i>
                  <div class="span">购物车</div>
                  <span class="cart_num">{{cartNum}}</span>
                </router-link>
              </li>
              <li>
                <a href="javascript:void(0);" class="mpbtn_order"><i class="chongzhi"></i></a>
                <div class="mp_tooltip" style="left: -121px; visibility: hidden;">
                  <font style="font-size:12px; cursor:pointer;">我的订单</font>
                  <i class="icon_arrow_right_black"></i>
                </div>
              </li>
              <li>
                <a href="javascript:void(0);" class="mpbtn_yhq"><i class="yhq"></i></a>
                <div class="mp_tooltip" style="left: -121px; visibility: hidden;">
                  <font style="font-size:12px; cursor:pointer;">优惠券</font>
                  <i class="icon_arrow_right_black"></i>
                </div>
              </li>
              <li>
                <a href="javascript:void(0);" class="mpbtn_total"><i class="view"></i></a>
                <div class="mp_tooltip" style=" visibility:hidden;">
                  <font style="font-size:12px; cursor:pointer;">我的资产</font>
                  <i class="icon_arrow_right_black"></i>
                </div>
              </li>
              <li>
                <a href="javascript:void(0);" class="mpbtn_history"><i class="zuji"></i></a>
                <div class="mp_tooltip" style="left: -121px; visibility: hidden;">
                  <font style="font-size:12px; cursor:pointer;">我的足迹</font>
                  <i class="icon_arrow_right_black"></i>
                </div>
              </li>
              <li>
                <a href="javascript:void(0);" class="mpbtn_collection"><i class="wdsc"></i></a>
                <div class="mp_tooltip">
                  <font style="font-size:12px; cursor:pointer;">我的收藏</font>
                  <i class="icon_arrow_right_black"></i>
                </div>
              </li>
              <li>
                <a href="javascript:void(0);" class="mpbtn_email"><i class="email"></i></a>
                <div class="mp_tooltip">
                  <font style="font-size:12px; cursor:pointer;">邮箱订阅</font>
                  <i class="icon_arrow_right_black"></i>
                </div>
              </li>
            </ul>
          </div>
          <div class="quick_toggle">
            <ul>
              <li>
                <a id="IM" im_type="dsc" onclick="openWin(this)" href="javascript:;">
                  <i class="kfzx"></i>
                </a>
                <div class="mp_tooltip">
                  客服中心<i class="icon_arrow_right_black"></i>
                </div>
              </li>
              <li class="returnTop">
                <a href="javascript:void(0);" class="return_top"><i class="top"></i></a>
              </li>
            </ul>

          </div>
        </div>
        <div id="quick_links_pop" class="quick_links_pop"></div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import {addClass,removeClass} from '@/assets/js/utils'
import AmountBox from '@/components/AmountBox/Index'
import {getCategoryList} from '@/api/category'
import TopNav from '@/components/TopNav/Index'
import { mapGetters } from 'vuex'
import {deleteCart, setCartNum} from "@/api/user";
import {getList} from "@/api/mall/search/hot"

export default {
  name: "Index",
  components: {
    TopNav,
    // eslint-disable-next-line vue/no-unused-components
    AmountBox
  },
  data() {
    return {
      categories:[],
      shoppingCart: [],
      hostSearchList: [],
      keywords: '',
      more:{
        name : '',
        factory : '',
        specs : ''
      },
      searches:[
          '曼秀雷敦',
          '小林'
      ],
      showSearchMore:false,
    }
  },
  computed:{
    ...mapGetters(['cartNum','carPrice','cartList'])
  },
  created() {
    getCategoryList({}).then(res => {
      this.categories = res.data;
    }).catch(error => {
      console.log(error)
    })

    getList({}).then(res => {
      this.hostSearchList = res.data.list
    }).catch(error => {
      console.log(error)
    })
  },
  methods:{
    mouseOver($event,className='hover'){
      addClass($event.currentTarget,className);
    },
    mouseLeave($event,className='hover') {
      removeClass($event.currentTarget,className)
    },
    hideCate(){
      this.$refs.cateNav.className = 'nav dsc-zoom'
      this.$refs.cateDropDown.className = 'categorys site-mast'
    },
    search(){
      if(this.keywords.trim() == '' && !this.showSearchMore)
        return

      //如果是高级搜索，keywords值要改变
      if(this.showSearchMore){
        if(this.more.name.trim() == ''){
          this.$message({
            message: '品名不能为空！',
            type: 'warning'
          });
          return;
        }
        if(this.more.specs.trim() != '' && this.more.factory.trim() == ''){
          this.$message({
            message: '产地不能为空！',
            type: 'warning'
          });
          return;
        }
        this.keywords = this.more.name + ' ' + this.more.factory + ' ' + this.more.specs;
      }

      if(this.$route.path=='/'){
        let routerJump = this.$router.resolve({ path: '/search', query: { 'keywords':this.keywords } })
        window.open(routerJump.href, '_blank')
      }else{
        this.$router.push({path: '/search', query: { 'keywords':this.keywords }})
      }
    },
    delCart(cart){
      deleteCart({id:cart.id}).then(() => {
        this.$store.dispatch('GetCartSum')
      })
    },
    numChange(cart){
      // this.cartLoading = true
      setCartNum({
        id:cart.productId,
        nums : cart.nums
      }).then(res => {
        this.$message({
          type: 'success',
          message: res.otherData.msg
        });
        this.$store.dispatch('GetCartSum')
      }).finally(()=>{
        // this.cartLoading = false
      })
    },
  }
}
</script>
<style>
.buy-num{
  width: 20px !important;
}
</style>
<style scoped>
.categorys-items .selected .categorys-items-layer{
  display: block !important;
}
.cate_channel{
  height: 100%;
}
.cate_channel a{
  margin-bottom: 20px;
}
.dsc-search{
  width: 700px;
}
.dsc-search .search-text{
  width: 519px;
}

.hot-img {
  position: relative;
  top: -15px;
  left: -5px;
  /*height: 20px;*/
  /*width: 20px;*/
}

.search-label-more{
  border: 2px solid #f42424;
  height: 30px;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-block;
  line-height: 30px;
  font-weight: bold;
}
.search-input-more{
  width: 127px;
  height: 30px;
  border: 2px solid #f42424;
  border-right: none;
  border-left: none;
  padding-left: 8px;
}
.search-input-more:focus{
  outline: none;
}
</style>
