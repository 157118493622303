<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>



        <div class="view" style="margin-bottom: 10px; overflow: hidden;height: 500px;font-size: 18px;position: relative;">
          <img src="http://image.yabyy.com/home/sanbajie/000.png" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);">
        </div>

        <div class="visual-item w1200" style="display: block;">

          <div class="view" >
            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="success"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="info"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="warning"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="error"-->
<!--                effect="dark" style="margin-bottom: 20px" >-->
<!--            </el-alert>-->
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/sanbajie/999.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img" style="width: 230px">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank" tag="a">
                    <div class="p-img" style="width: 230px"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img" style="width: 230px">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank" tag="a">
                    <div class="p-img" style="width: 230px"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img" style="width: 230px">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank" tag="a">
                    <div class="p-img" style="width: 230px"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img" style="width: 230px">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank" tag="a">
                    <div class="p-img" style="width: 230px"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img" style="width: 230px">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank" tag="a">
                    <div class="p-img" style="width: 230px"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/sanbajie/001.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/sanbajie/002.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[10].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[10].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[11].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[11].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[12].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[12].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[13].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[13].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[14].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[14].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[15].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[15].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[16].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[16].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/sanbajie/003.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[17].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[17].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[18].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[18].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[19].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[19].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[20].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[20].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>


          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/sanbajie/004.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[21].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[21].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[22].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[22].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[23].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[23].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[24].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[24].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[25].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[25].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[26].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[26].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[27].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[27].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[28].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[28].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>


          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/sanbajie/005.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[29].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[29].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[30].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[30].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[31].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[31].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[32].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[32].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/sanbajie/006.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[33].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[33].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[34].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[34].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[35].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[35].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[36].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[36].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/sanbajie/007.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[37].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[37].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[38].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[38].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[39].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[39].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[40].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[40].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[41].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[41].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[42].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[42].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[43].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[43].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[44].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[44].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[45].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[45].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[46].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[46].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[47].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[47].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[48].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[48].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>



          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/sanbajie/008.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[49].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[49].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[50].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[50].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[51].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[51].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[52].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[52].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

            <div class="view" style="margin-bottom: 10px;">
              <img src="http://image.yabyy.com/home/sanbajie/009.png">
            </div>
            <div class="view">
              <div class="store-channel">
                <div class="rec-store-list">
                  <div class="rec-store-item opacity_img">
                    <router-link :to="{path:'/goods',query:{id:Floor[53].id}}" target="_blank" tag="a">
                      <div class="p-img"><img :src="Floor[53].url"></div>
                    </router-link>
                  </div>
                  <div class="rec-store-item opacity_img">
                    <router-link :to="{path:'/goods',query:{id:Floor[54].id}}" target="_blank" tag="a">
                      <div class="p-img"><img :src="Floor[54].url"></div>
                    </router-link>
                  </div>
                  <div class="rec-store-item opacity_img">
                    <router-link :to="{path:'/goods',query:{id:Floor[55].id}}" target="_blank" tag="a">
                      <div class="p-img"><img :src="Floor[55].url"></div>
                    </router-link>
                  </div>
                  <div class="rec-store-item opacity_img">
                    <router-link :to="{path:'/goods',query:{id:Floor[56].id}}" target="_blank" tag="a">
                      <div class="p-img"><img :src="Floor[56].url"></div>
                    </router-link>
                  </div>
                </div>
              </div>
          </div>
            <div class="view">
              <div class="store-channel">
                <div class="rec-store-list">
                  <div class="rec-store-item opacity_img">
                    <router-link :to="{path:'/goods',query:{id:Floor[57].id}}" target="_blank" tag="a">
                      <div class="p-img"><img :src="Floor[57].url"></div>
                    </router-link>
                  </div>
                  <div class="rec-store-item opacity_img">
                    <router-link :to="{path:'/goods',query:{id:Floor[58].id}}" target="_blank" tag="a">
                      <div class="p-img"><img :src="Floor[58].url"></div>
                    </router-link>
                  </div>
                  <div class="rec-store-item opacity_img">
                    <router-link :to="{path:'/goods',query:{id:Floor[59].id}}" target="_blank" tag="a">
                      <div class="p-img"><img :src="Floor[59].url"></div>
                    </router-link>
                  </div>

                </div>
              </div>
            </div>


        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "sanbajie",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[

        {'index':0,'url':'http://image.yabyy.com/home/sanbajie/1000946.png','id':'4757','search':''},      //4757	1000946	洁尔阴洗液
        {'index':1,'url':'http://image.yabyy.com/home/sanbajie/1001661.png','id':'5246','search':''},       //5246	1001661	洁尔阴洗液
        {'index':2,'url':'http://image.yabyy.com/home/sanbajie/1005090.png','id':'7969','search':''},     //7969	1005090	草本抑菌洗液
        {'index':3,'url':'http://image.yabyy.com/home/sanbajie/1005262.png','id':'8112','search':''},      //8112	1005262	洁尔阴洗液
        {'index':4,'url':'http://image.yabyy.com/home/sanbajie/1011512.png','id':'11839','search':''},     //11839	1011512	洁尔阴洗液

        {'index':5,'url':'http://image.yabyy.com/home/sanbajie/1004975.png','id':'7878','search':''},      //7878	1004975	碧生源牌常润茶
        {'index':6,'url':'http://image.yabyy.com/home/sanbajie/1005070.png','id':'7953','search':''},      //7953	1005070	回春花牌通秘茶
        {'index':7,'url':'http://image.yabyy.com/home/sanbajie/1007819.png','id':'9752','search':''},      //9752	1007819	常菁茶
        {'index':8,'url':'http://image.yabyy.com/home/sanbajie/1005650.png','id':'8346','search':''},      //8346	1005650	排毒养颜胶囊


        {'index':9,'url':'http://image.yabyy.com/home/sanbajie/1009624.png','id':'10790','search':''},      //10790	1009624	脾肾两助丸
        {'index':10,'url':'http://image.yabyy.com/home/sanbajie/1003333.png','id':'6591','search':''},      //6591	1003333	黄芪颗粒
        {'index':11,'url':'http://image.yabyy.com/home/sanbajie/1002081.png','id':'5595','search':''},      //5595	1002081	十全大补丸(浓缩丸)
        {'index':12,'url':'http://image.yabyy.com/home/sanbajie/1007831.png','id':'9760','search':''},      //9760	1007831	人参归脾丸
        {'index':13,'url':'http://image.yabyy.com/home/sanbajie/1007277.png','id':'9409','search':''},      //9409	1007277	六味地黄胶囊
        {'index':14,'url':'http://image.yabyy.com/home/sanbajie/1001739.png','id':'5313','search':''},      //5313	1001739	生脉饮(党参方)
        {'index':15,'url':'http://image.yabyy.com/home/sanbajie/1002074.png','id':'5588','search':''},      //5588	1002074	桂附地黄丸(浓缩丸)
        {'index':16,'url':'http://image.yabyy.com/home/sanbajie/1013083.png','id':'12616','search':''},      //12616	1013083	金水宝胶囊

        {'index':17,'url':'http://image.yabyy.com/home/sanbajie/1001321.png','id':'5006','search':''},      //5006	1001321	百合康牌褪黑素维生素B6胶囊
        {'index':18,'url':'http://image.yabyy.com/home/sanbajie/1007212.png','id':'9361','search':''},      //9361	1007212	神象R珍珠粉
        {'index':19,'url':'http://image.yabyy.com/home/sanbajie/1011303.png','id':'11723','search':''},      //11723	1011303	脑白金胶囊、口服液(礼盒)
        {'index':20,'url':'http://image.yabyy.com/home/sanbajie/1036919.png','id':'18039','search':''},      //18039	1036919	康富丽牌褪黑素软胶囊

        {'index':21,'url':'http://image.yabyy.com/home/sanbajie/1002769.png','id':'6139','search':''},      //6139	1002769	田七痛经胶囊
        {'index':22,'url':'http://image.yabyy.com/home/sanbajie/1007968.png','id':'9837','search':''},      //9837	1007968	艾附暖宫丸
        {'index':23,'url':'http://image.yabyy.com/home/sanbajie/1002023.png','id':'5549','search':''},      //5549	1002023	浓缩当归丸
        {'index':24,'url':'http://image.yabyy.com/home/sanbajie/1003811.png','id':'6952','search':''},      //6952	1003811	桂枝茯苓丸
        {'index':25,'url':'http://image.yabyy.com/home/sanbajie/1003055.png','id':'6381','search':''},      //6381	1003055	乌鸡白凤丸(水蜜丸)
        {'index':26,'url':'http://image.yabyy.com/home/sanbajie/1010093.png','id':'11080','search':''},      //11080	1010093	叶酸片
        {'index':27,'url':'http://image.yabyy.com/home/sanbajie/1014572.png','id':'13346','search':''},      //13346	1014572	乳癖消片(薄膜衣片)
        {'index':28,'url':'http://image.yabyy.com/home/sanbajie/1040733.png','id':'20414','search':''},      //20414	1040733	左炔诺孕酮片

        {'index':29,'url':'http://image.yabyy.com/home/sanbajie/1014973.png','id':'13548','search':''},      //13548	1014973	奥维斯牌蜂胶软胶囊
        {'index':30,'url':'http://image.yabyy.com/home/sanbajie/1015568.png','id':'13923','search':''},      //13923	1015568	金奥力牌破壁灵芝孢子粉胶囊
        {'index':31,'url':'http://image.yabyy.com/home/sanbajie/1015834.png','id':'14110','search':''},      //14110	1015834	百合康牌苦瓜西洋参铬软胶囊
        {'index':32,'url':'http://image.yabyy.com/home/sanbajie/1005997.png','id':'8556','search':''},      //8556	1005997	维澳佳牌大豆磷脂维E软胶囊

        {'index':33,'url':'http://image.yabyy.com/home/sanbajie/1007200.png','id':'9354','search':''},      //9354	1007200	热淋清片
        {'index':34,'url':'http://image.yabyy.com/home/sanbajie/1033615.png','id':'15686','search':''},      //15686	1033615	热淋清颗粒(无糖型)
        {'index':35,'url':'http://image.yabyy.com/home/sanbajie/1033946.png','id':'16205','search':''},      //16205	1033946	枸橼酸西地那非片
        {'index':36,'url':'http://image.yabyy.com/home/sanbajie/1040533.png','id':'20180','search':''},      //20180	1040533	他达拉非片(薄膜衣片)

        {'index':37,'url':'http://image.yabyy.com/home/sanbajie/1010661.png','id':'11359','search':''},      //11359	1010661	益普利生牌维生素C咀嚼片(香橙味)
        {'index':38,'url':'http://image.yabyy.com/home/sanbajie/1010320.png','id':'11188','search':''},      //11188	1010320	金奥力牌钙维生素D软胶囊
        {'index':39,'url':'http://image.yabyy.com/home/sanbajie/1015965.png','id':'14220','search':''},      //14220	1015965	康富来牌血尔口服液
        {'index':40,'url':'http://image.yabyy.com/home/sanbajie/1015521.png','id':'13889','search':''},      //13889	1015521	百合康牌氨基葡萄糖硫酸软骨素钙胶囊
        {'index':41,'url':'http://image.yabyy.com/home/sanbajie/1009373.png','id':'10653','search':''},      //10653	1009373	金奥力牌辅酶Q10软胶囊
        {'index':42,'url':'http://image.yabyy.com/home/sanbajie/1008811.png','id':'10301','search':''},      //10301	1008811	金奥力牌葡萄籽维E软胶囊
        {'index':43,'url':'http://image.yabyy.com/home/sanbajie/1015951.png','id':'14206','search':''},      //14206	1015951	血尔口服液
        {'index':44,'url':'http://image.yabyy.com/home/sanbajie/1014244.png','id':'13135','search':''},      //13135	1014244	益普利生牌钙维生素D软胶囊
        {'index':45,'url':'http://image.yabyy.com/home/sanbajie/1004439.png','id':'7448','search':''},      //7448	1004439	多种维生素矿物质片(中老年型)(礼盒)
        {'index':46,'url':'http://image.yabyy.com/home/sanbajie/1013137.png','id':'12648','search':''},      //12648	1013137	swisse钙维生素D片
        {'index':47,'url':'http://image.yabyy.com/home/sanbajie/1012205.png','id':'12187','search':''},      //12187	1012205	汉苑良方百消丹牌玉妍丸
        {'index':48,'url':'http://image.yabyy.com/home/sanbajie/1037723.png','id':'19204','search':''},      //19204	1037723	百合康牌胶原蛋白大豆提取物软胶囊

        {'index':49,'url':'http://image.yabyy.com/home/sanbajie/1001696.png','id':'5275','search':''},      //5275	1001696	双唑泰阴道泡腾片
        {'index':50,'url':'http://image.yabyy.com/home/sanbajie/1015979.png','id':'14231','search':''},      //14231	1015979	复方苦参洗剂(带冲洗器)
        {'index':51,'url':'http://image.yabyy.com/home/sanbajie/1031673.png','id':'14911','search':''},      //14911	1031673	双唑泰乳膏
        {'index':52,'url':'http://image.yabyy.com/home/sanbajie/1037862.png','id':'18912','search':''},      //18912	1037862	日舒安洗液

        {'index':53,'url':'http://image.yabyy.com/home/sanbajie/1001090.png','id':'4860','search':''},      //4860	1001090	天然胶乳橡胶避孕套(质感超薄)
        {'index':54,'url':'http://image.yabyy.com/home/sanbajie/1005160.png','id':'8030','search':''},      //8030	1005160	天然胶乳橡胶避孕套(超润滑)
        {'index':55,'url':'http://image.yabyy.com/home/sanbajie/1006442.png','id':'8836','search':''},      //8836	1006442	天然胶乳橡胶避孕套(LOVE装)
        {'index':56,'url':'http://image.yabyy.com/home/sanbajie/1006842.png','id':'9113','search':''},      //9113	1006842	人绒毛膜促性腺激素(HCG)测定试纸(胶体金法)
        {'index':57,'url':'http://image.yabyy.com/home/sanbajie/1003496.png','id':'6713','search':''},      //6713	1003496	人绒毛膜促性腺激素(HCG)检测试纸(胶体金法)
        {'index':58,'url':'http://image.yabyy.com/home/sanbajie/1009452.png','id':'10690','search':''},      //10690	1009452	天然胶乳橡胶避孕套(活力超薄)
        {'index':59,'url':'http://image.yabyy.com/home/sanbajie/1023873.png','id':'14573','search':''},      //14573	1023873	人绒毛膜促性腺激素(HCG)诊断试剂盒(胶体金免疫层析法)
      ]
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
