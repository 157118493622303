import request from '@/utils/request'

// export function getAllCategories(){
//     return reqest({
//         url: '/api/Good/GetAllCategories',
//         method: 'post',
//         needToken: false,
//     })
// }

export function getCategoryList(params) {
    return request({
        url: '/app/mall/goods/category/list',
        method: 'post',
        needToken: false,
        data: params
    })
}



export function getCategoryCrumbs(id) {
    return request({
        url: `/app/mall/goods/category/crumbs/${id}`,
        method: 'get',
        needToken: false,
    })
}

