// 操作dom
export function getClass (ele) {
    return ele.className.replace(/\s+/, ' ').split(' ')
}

export function hasClass (ele, cls) {
    return (' ' + ele.className + ' ').indexOf(' ' + cls + ' ') > -1
}

export function addClass (ele, cls) {
    if (!hasClass(ele, cls)) {
        ele.className += ' ' + cls
        var curClass = ele.className

        var classes = (cls || '').split(' ')
        for (var i = 0, j = classes.length; i < j; i++) {
            var clsName = classes[i]
            if (!clsName) continue

            if (ele.classList) {
                ele.classList.add(clsName)
            } else if (!hasClass(ele, clsName)) {
                curClass += ' ' + clsName
            }
        }
        if (!ele.classList) {
            ele.className = curClass
        }
    }
}

export function removeClass (ele, cls) {
    if (hasClass(ele, cls)) {
        var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)', 'gi')
        ele.className = ele.className.replace(reg, ' ')
    }
}

export function toggleClass (ele, cls) {
    if (hasClass(ele, cls)) {
        removeClass(ele, cls)
    } else {
        addClass(ele, cls)
    }
}
