<template>
  <div class="amount-warp">
    <input type="text" v-model="counter_num" @keyup="fixNumber" @change="fixNumber" v-on:input="fixNumber" class="text buy-num">
    <div class="a-btn">
      <a href="javascript:void(0);" v-on:click="add" class="btn-add"><i class="iconfont icon-up"></i></a>
      <a href="javascript:void(0);" v-on:click="minus" class="btn-reduce btn-disabled"><i class="iconfont icon-down"></i></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AmountBox",
  props: {
    max: {
      type: Number,
      default: 999
    },
    min: {
      type: Number,
      default: 1
    },
    defaultVal:{
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      counter_num: this.defaultVal == 0 ? this.min : this.defaultVal,
      min_num: this.min,
      max_num: this.max
    }
  },
  methods: {
    minus() {
      if (this.counter_num <= this.min_num) {
        this.counter_num = this.min_num
      } else {
        this.counter_num--
      }
    },
    add() {
      if (this.counter_num >= this.max_num) {
        this.counter_num = this.max_num
      } else {
        this.counter_num++
      }
    },
    // 输入框改变
    handleInput: function(e){
      let value = e.target.value ;
      let emitValue = 1 ;
      // 防止输入不合法
      emitValue = parseInt(value);
      if(emitValue > this.max_num) {
        emitValue = this.max_num ;
      }
      if( emitValue < this.min_num) {
        emitValue = parseInt(this.min_num) ;
      }
      this.counter_num = emitValue ;
    },
    fixNumber () {
      let fix
      if (typeof this.counter_num === 'string') {
        fix = Number(this.counter_num.replace(/\D/g, ''))
      }
      else {
        fix = this.counter_num
      }
      if (fix > this.max_num || fix < this.min_num) {
        fix = this.min_num
      }
      this.counter_num = fix
    },
  },
  watch: {
    counter_num() {
      this.$emit('on-change', this.counter_num)
      this.$emit('input', this.counter_num)

      this.$emit('update');
    },
    defaultVal(){
      this.counter_num = this.defaultVal == 0 ? this.min_num : this.defaultVal
    }
    // immediate: true
  }
}
</script>

<style scoped>
/*.buy-num {*/
/*  width: 36px;*/
/*}*/
</style>
